<template>
  <div class="prime-view-container fixed-full-height">
    <ion-row class="full-height overflow-y-scroll pad-top-twenty pad-bottom-twenty">
      <ion-col class="column-stretch-wrap full-height my-card" size="4">
        <div class="prime-active-conversations-header column-stretch-header pad-twenty">
          <h5 class="margin-death">Conversations</h5>
          <input
            @input="debounceSearch($event)"
            class="form-control margin-top-ten"
            type="text"
            placeholder="Search conversations..."
          />
        </div>
        <div
          class="prime-internal-scoll-card ion-card column-stretch-body pad-lr-twenty overflow-y-scroll prime-conversation-list-container"
        >
          <div v-if="loadingConvos" class="text-center pad-ten loading-overlay center-center">
            <ion-spinner name="lines" class="spinner-xlarge" color="dark"></ion-spinner>
          </div>
          <div
            v-if="recentConvos.length == 0 && !loadingConvos"
            class="text-center pad-ten center-center"
          >
            <div>There are no conversations</div>
          </div>
          <div v-else>
            <ion-card
              class="margin-bottom-twenty cursor-pointer"
              :color="convo.id === activeListItem ? 'primary' : 'light'"
              v-for="(convo, key) in recentConvos"
              :key="key"
            >
              <ion-row
                @click="getMessages(convo.id, convo.description, convo.last_message_date, convo)"
              >
                <ion-col class="margin-right-ten" size="auto">
                  <IconChatBubble class="icon-small" />
                </ion-col>
                <ion-col class="prime-past-conversation-content">
                  <div
                    class="prime-past-conversation-title"
                    v-if="titleChanged && convo.id === activeListItem"
                  >
                    {{ convoTitle }}
                  </div>
                  <div class="prime-past-conversation-title" v-else>{{ convo.description }}</div>
                  <div class="prime-past-conversation-time">
                    Last interaction
                    {{ convo.last_message_date | moment("from", "now") }} ({{
                      convo.last_message_date | moment("M/D/YYYY")
                    }})
                  </div>
                </ion-col>
              </ion-row>
            </ion-card>
          </div>
        </div>
        {{ currentPatient.enrollmentStatus }}
        <div
          v-if="$can(I.SEND_MESSAGE)"
          class="pad-twenty prime-recent-conversations-footer column-stretch-footer top-divider"
        >
          <button
            v-if="currentPatient.payload.enrollmentStatus == 'approved'"
            @click="goToConversation()"
            class="prime-button button-primary button-block"
          >
            Chat With Patient
          </button>
          <button
            v-else
            v.tooltip="Patient is not enrolled in LumerisEngage."
            class="prime-button button-primary disabled button-block"
          >
            Chat With Patient
          </button>
        </div>
        <div
          v-else
          class="pad-twenty prime-recent-conversations-footer column-stretch-footer top-divider disabled"
          v-tooltip.right="{ content: $orgMissingPermissions('The Conversation feature') }"
        >
          <button
            v-if="currentPatient.payload.enrollmentStatus == 'approved'"
            class="prime-button button-primary button-block"
          >
            Chat With Patient
          </button>
          <button
            v-else
            v.tooltip="Patient is not enrolled in LumerisEngage."
            class="prime-button button-primary disabled button-block"
          >
            Chat With Patient
          </button>
        </div>
      </ion-col>
      <ion-col class="full-height overflow-y-scroll">
        <div
          v-if="loadingMessage"
          class="column-stretch-body centerItems text-center text-muted display-flex valign full-height"
        >
          <ion-spinner name="lines" class="spinner-large" color="dark"></ion-spinner>
        </div>
        <div
          v-if="!activeListItem"
          class="column-stretch-body centerItems text-center text-muted display-flex valign full-height"
        >
          No conversation selected
        </div>
        <div class="prime-modal" v-else-if="messages.length > 0 && !loadingMessage">
          <ion-card color="white" class="pad-twenty">
            <ion-row class="ion-align-items-center">
              <ion-col size="auto">
                <IconChatBubble class="icon-small center-center margin-right-ten" />
              </ion-col>
              <ion-col>
                <h5 class="margin-death" :key="convoTitle + convoObject.id">
                  {{ convoTitle }}
                  <span
                    v-if="$can(I.SEND_TRANSCRIPT_TO_EHR)"
                    @click="sendTranscript()"
                    lines="none"
                    class="edit-action cursor-pointer"
                  >
                    <IconSend
                      v-tooltip.top="'Send Transcript to EHR'"
                      class="icon-small icon-dark-grey margin-right-ten"
                    />
                  </span>
                  <span
                    v-else
                    lines="none"
                    class="edit-action disabled"
                    v-tooltip.top-start="{
                      content: $orgMissingPermissions('The Sending Transcript to EHR feature')
                    }"
                  >
                    <IconSend class="icon-small icon-dark-grey margin-right-ten" />
                  </span>
                  <span @click="copyTranscript()" lines="none" class="edit-action cursor-pointer">
                    <IconCopy
                      v-tooltip.top="'Copy Transcript'"
                      class="icon-small icon-dark-grey margin-right-ten"
                    />
                  </span>
                  <span
                    v-if="$can(I.MODIFY_PATIENT_DATA)"
                    @click="showEditSubjectPopover()"
                    lines="none"
                    class="edit-action cursor-pointer"
                  >
                    <IconEditPencil
                      v-tooltip.top="'Edit Conversation Title'"
                      class="icon-small icon-dark-grey margin-right-ten"
                    />
                  </span>
                  <span
                    v-else
                    lines="none"
                    class="disabled edit-action cursor-pointer"
                    v-tooltip.top-start="{
                      content: $orgMissingPermissions('The Conversation feature')
                    }"
                  >
                    <IconEditPencil class="icon-small icon-dark-grey margin-right-ten" />
                  </span>
                </h5>
                <div class="text-muted text-small">
                  Last interaction {{ convoLastMessageDate | moment("from", "now") }} ({{
                    convoLastMessageDate | moment("M/D/YYYY")
                  }})
                </div>
              </ion-col>
            </ion-row>
          </ion-card>
          <div class="pad-twenty">
            <ChatBubble
              v-for="message in messages"
              :key="message.id"
              v-bind="{
                message
              }"
            />
          </div>
        </div>
      </ion-col>
    </ion-row>
    <MessagesCopyToClipboard :messages="messages" />
  </div>
</template>

<script>
import ChatBubble from "@/components/Chat/ChatBubble";
import MessageEditConvoSubject from "@/components/Chat/MessageEditConvoSubject";
import MessagesCopyToClipboard from "@/components/Chat/MessagesCopyToClipboard";
import IconChatBubble from "@/components/Global/Icons/IconChatBubble";
import IconCopy from "@/components/Global/Icons/IconCopy";
import IconEditPencil from "@/components/Global/Icons/IconEditPencil";
import IconSend from "@/components/Global/Icons/IconSend";
import router from "@/router";
import { send as httpSend } from "@/services/Api";
import { EventBus } from "@/services/Events.js";
import _ from "lodash";

export default {
  name: "Conversations",
  props: {
    currentPatient: Object
  },
  data() {
    return {
      recentConvos: [],
      isGettingRecentConvos: false,
      activeListItem: undefined,
      interactionId: undefined,
      status: undefined,
      messages: [],
      loadingMessage: false,
      loadingConvos: false,
      convoTitle: undefined,
      convoLastMessageDate: undefined,
      convoObject: {},
      titleChanged: false
    };
  },
  components: {
    IconChatBubble,
    ChatBubble,
    IconEditPencil,
    IconSend,
    IconCopy,
    MessagesCopyToClipboard
  },
  mounted() {
    this.getRecentConvos();
    EventBus.$on("conversationUpdated", (_iid, _mc, messageTitle) => {
      if (this.convoTitle !== messageTitle) {
        this.titleChanged = true;
        this.convoTitle = messageTitle;
      }
    });
  },
  methods: {
    debounceSearch: _.debounce(function(evt) {
      this.getRecentConvos(evt.target.value);
    }, 750),
    showEditSubjectPopover: function() {
      this.titleChanged = false;
      this.$ionic.popoverController
        .create({
          component: MessageEditConvoSubject,
          // uncomment for popover snap to element
          // event: ev,
          cssClass: "popover-width-lg",
          mode: "ios",
          componentProps: {
            data: {
              messageTitle: this.convoTitle,
              interactionId: this.activeListItem,
              initiator: this.convoObject.item_type,
              status: this.convoObject.status,
              beneficiaryId: this.convoObject.beneficiary_id,
              urgency: this.convoObject.urgency
            }
          }
        })
        .then(m => m.present());
    },
    copyTranscript() {
      EventBus.$emit("copyMessagesToClipboard");
    },
    sendTranscript() {
      const payload = {
        account_id: this.currentPatient.payload.beneficiary.accountId,
        payload: {
          beneficiary_id: this.convoObject.beneficiary_id,
          interaction_id: this.activeListItem
        },
        subject: this.convoTitle
      };

      const method = "post";
      const path = document.config.transcriptToEHR + "send_transcript_to_ehr";

      httpSend({ path, method, authToken: true, payload })
        .then(() => {
          this.$ionic.toastController
            .create({
              header: "Success!",
              message: "The transcript has been sent.",
              duration: 4000,
              color: "primary",
              mode: "ios"
            })
            .then(m => m.present());
        })
        .catch(error => {
          this.$ionic.toastController
            .create({
              header: "Could not send transcript. Please try again later.",
              message: error,
              duration: 10000,
              position: "top"
            })
            .then(m => m.present());
          khanSolo.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    getRecentConvos: async function(searchTerm) {
      this.loadingConvos = true;
      const method = "get";
      const path = searchTerm
        ? document.config.patientApi +
          this.currentPatient.payload.beneficiary.id +
          "/conversations?search=" +
          searchTerm
        : document.config.patientApi +
          this.currentPatient.payload.beneficiary.id +
          "/conversations";

      httpSend({ path, method, authToken: true })
        .then(response => {
          this.recentConvos = response.data;
          this.loadingConvos = false;
        })
        .catch(error => {
          khanSolo.log(error);
          this.loadingConvos = false;
        });
    },
    getMessages: async function(interactionId, title, lastMessageDate, convoObj) {
      this.loadingMessage = true;
      const method = "get";
      const path = document.config.messageApi + interactionId + "/messages";
      this.convoTitle = title;
      this.activeListItem = interactionId;
      this.convoLastMessageDate = lastMessageDate;
      this.convoObject = convoObj;
      // TODO update to use fhir api search endpoint
      httpSend({ path, method, authToken: true })
        .then(result => {
          // this.status = result.data.status;
          this.messages = result.data.messages;
          // this.updateMessages(result.data.messages);
          this.loadingMessage = false;
          this.convoObject = { ...this.convoObject, urgency: this.messages[0].urgency };
        })
        .catch(() => {
          this.$ionic.toastController
            .create({
              header: "Error",
              message: "Could not get conversation. Please try again later.",
              duration: 5000,
              position: "top"
            })
            .then(m => m.present());
          this.loadingMessage = false;
        });
    },
    updateMessages(messages) {
      // concat arrays and reduce to remove duplicates
      this.messages = [...this.messages, ...messages].reduce((acc, current) => {
        const x = acc.find(item => item.id === current.id);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    },
    goToConversation() {
      router.push({
        path: "/professional?patient=" + this.currentPatient.payload.beneficiary.id
      });
    }
  },
  computed: {
    filteredMessages() {
      const dedupedMessages = [...this.messages]
        .reduce((acc, current) => {
          const x = acc.find(item => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, [])
        .sort((a, b) => a.id - b.id);

      const sortedMessages = [...dedupedMessages].sort((a, b) => a.id - b.id);
      const filteredMessages = sortedMessages.filter(
        message => message.channel === this.msgChannel || this.msgChannel == "all"
      );

      return filteredMessages;
    }
  }
};
</script>

<style scoped>
.my-card {
  background-color: #fff;
  border-radius: 8px;
}
.column-stretch-wrap {
  display: flex;
  flex-direction: column;
}

.column-stretch-header {
  flex: 0 0 auto;
}

.column-stretch-body {
  flex: 1 1 auto;
  position: relative; /* need this to position inner content */
  overflow-y: auto;
}

.column-stretch-footer {
  flex: 0 0 auto;
}

.prime-internal-scoll-card ion-card {
  margin: 0 0 10px 0 !important;
  box-shadow: 0 1px 6px 0px rgba(46, 61, 73, 0.2) !important;
}

.prime-past-conversation-container {
  cursor: pointer;
  padding: 15px 20px;
  display: flex;
}

.prime-past-conversation-title {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prime-past-conversation-time {
  font-size: 12px;
  font-weight: 500;
  color: var(--ion-color-medium);
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
ion-card.ion-color-primary svg {
  fill: #fff;
}
.add-patient-card-scroll {
  height: 600px;
  overflow-y: scroll;
}
.edit-action {
  float: right;
}

.disabled svg {
  fill: var(--ion-color-medium) !important;
}
</style>
